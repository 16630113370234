import { modalCallback, lightboxCallback } from "../callbacks/pro";
import { chartsCallback } from "../callbacks/charts";

import * as freeSelectors from "./free";

const defaultInitSelectors = {
  ...freeSelectors.default,
  alert: {
    name: "Alert",
    selector: "[data-twe-alert-init]",
    isToggler: false,
  },
  animation: {
    name: "Animate",
    selector: "[data-twe-animation-init]",
    isToggler: false,
  },
  chips: {
    name: "ChipsInput",
    selector: "[data-twe-chips-input-init]",
    isToggler: false,
  },
  chip: {
    name: "Chip",
    selector: "[data-twe-chip-init]",
    isToggler: false,
    onInit: "init",
  },
  datepicker: {
    name: "Datepicker",
    selector: "[data-twe-datepicker-init]",
    isToggler: false,
  },
  datetimepicker: {
    name: "Datetimepicker",
    selector: "[data-twe-date-timepicker-init]",
    isToggler: false,
  },
  perfectScrollbar: {
    name: "PerfectScrollbar",
    selector: "[data-twe-perfect-scrollbar-init]",
    isToggler: false,
  },
  rating: {
    name: "Rating",
    selector: "[data-twe-rating-init]",
    isToggler: false,
  },
  select: {
    name: "Select",
    selector: "[data-twe-select-init]",
    isToggler: false,
  },
  sidenav: {
    name: "Sidenav",
    selector: "[data-twe-sidenav-init]",
    isToggler: false,
  },
  stepper: {
    name: "Stepper",
    selector: "[data-twe-stepper-init]",
    isToggler: false,
  },
  timepicker: {
    name: "Timepicker",
    selector: "[data-twe-timepicker-init]",
    isToggler: false,
  },
  toast: {
    name: "Toast",
    selector: "[data-twe-toast-init]",
    isToggler: false,
  },
  datatable: {
    name: "Datatable",
    selector: "[data-twe-datatable-init]",
  },
  popconfirm: {
    name: "Popconfirm",
    selector: "[data-twe-toggle='popconfirm']",
  },
  validation: {
    name: "Validation",
    selector: "[data-twe-validation-init]",
  },
  smoothScroll: {
    name: "SmoothScroll",
    selector: "a[data-twe-smooth-scroll-init]",
  },
  lazyLoad: {
    name: "LazyLoad",
    selector: "[data-twe-lazy-load-init]",
  },
  clipboard: {
    name: "Clipboard",
    selector: "[data-twe-clipboard-init]",
  },
  infiniteScroll: {
    name: "InfiniteScroll",
    selector: "[data-twe-infinite-scroll-init]",
  },
  loadingManagement: {
    name: "LoadingManagement",
    selector: "[data-twe-loading-management-init]",
  },
  sticky: {
    name: "Sticky",
    selector: "[data-twe-sticky-init]",
  },
  multiRangeSlider: {
    name: "MultiRangeSlider",
    selector: "[data-twe-multi-range-slider-init]",
  },
  touch: {
    name: "Touch",
    selector: "[data-twe-touch-init]",
  },

  // advancedInits
  chart: {
    name: "Chart",
    selector: "[data-twe-chart]",
    isToggler: false,
    advanced: chartsCallback,
  },

  // togglers
  modal: {
    name: "Modal",
    selector: "[data-twe-toggle='modal']",
    isToggler: true,
    callback: modalCallback,
  },
  lightbox: {
    name: "Lightbox",
    selector: "[data-twe-lightbox-init]",
    isToggler: true,
    callback: lightboxCallback,
  },
};

export default defaultInitSelectors;
