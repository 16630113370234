export const getIconButtonsTemplate = (
  datepickerIconTemplate,
  timepickerIconTemplate,
  classes
) => `
  <button type="button" class="${classes.pickerIcon}" data-twe-datepicker-toggle-button-ref>
    ${datepickerIconTemplate}
  </button>
  <button type="button" class="${classes.pickerIcon}" data-twe-timepicker-toggle-button-ref>
    ${timepickerIconTemplate}
  </button>
`;

export const getToggleButtonTemplate = (toggleButtonTemplate, classes) => `
  <button type="button" class="${classes.toggleButton}" data-twe-date-timepicker-toggle-ref>
    ${toggleButtonTemplate} 
  </button>
`;
