export const getInputField = ({ inputID, labelText }, classes) => {
  return `<div data-twe-chips-input-wrapper data-twe-input-wrapper-init class="${classes.chipsInputWrapper}">
      <input
          type="text"
          class="${classes.chipsInput}"
          id="${inputID}"
          placeholder="Example label" />
        <label
          for="${inputID}"
          class="${classes.chipsLabel}"
          >${labelText}
        </label>
      </div>
    </div>`;
};

export const getChip = ({ text, iconSVG }, classes) => {
  return `<div data-twe-chip-init data-twe-ripple-init class="${classes.chipElement}">
    <span data-twe-chip-text>${text}</span> 
      <span data-twe-chip-close class="${classes.chipCloseIcon}">
        ${iconSVG}
      </span>
  </div>`;
};
