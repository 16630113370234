const NAME = "stepper";
const ATTR_NAME = `data-twe-${NAME}`;

const ATTR_MOBILE_HEADER_REF = `${ATTR_NAME}-mobile-header-ref`;
const ATTR_MOBILE_FOOTER_REF = `${ATTR_NAME}-mobile-footer-ref`;

const ATTR_MOBILE_BTN_NEXT_REF = `${ATTR_NAME}-mobile-btn-next-ref`;
const ATTR_MOBILE_BTN_BACK_REF = `${ATTR_NAME}-mobile-btn-back-ref`;
const ATTR_MOBILE_ACTIVE_STEP_REF = `${ATTR_NAME}-mobile-active-step-ref`;
const ATTR_MOBILE_ALL_STEPS_REF = `${ATTR_NAME}-mobile-all-steps-ref`;
const ATTR_MOBILE_PROGRESS_BAR_REF = `${ATTR_NAME}-mobile-progress-bar-ref`;

const getMobileElementsTemplate = (classes, options) => {
  const mobileHeader = `
    <div
      ${ATTR_MOBILE_HEADER_REF}
      class="${classes.mobileHeader}">
      <p class="${classes.mobileHeaderText}">
        ${options.stepperMobileStepTxt} <span ${ATTR_MOBILE_ACTIVE_STEP_REF}></span> ${options.stepperMobileOfTxt}
        <span ${ATTR_MOBILE_ALL_STEPS_REF}></span>
      </p>
    </div>
  `;

  const mobileFooter = `
    <div
      ${ATTR_MOBILE_FOOTER_REF}
      class="${classes.mobileFooter}">      
    </div>    
  `;

  const mobileBtnBack = `
    <button
      type="button"
      class="${classes.mobileBtnBack}"
      ${ATTR_MOBILE_BTN_BACK_REF}>
      <span class="${classes.mobileBtnBackIcon}">
        ${options.stepperMobileBackBtnIcon}
      </span>
      ${options.stepperMobileBackBtn}
    </button>
  `;

  const mobileBtnNext = `
    <button
      type="button"
      class="${classes.mobileBtnNext}"
      ${ATTR_MOBILE_BTN_NEXT_REF}>
      ${options.stepperMobileNextBtn}
      <span class="${classes.mobileBtnNextIcon}">
       ${options.stepperMobileNextBtnIcon}
      </span>
    </button>
  `;

  const mobileProgressBar = `
    <div class="${classes.mobileProgressBarWrapper}">
      <div
        ${ATTR_MOBILE_PROGRESS_BAR_REF}
        class="${classes.mobileProgressBar}"></div>
    </div> 
  `;

  return {
    mobileHeader,
    mobileFooter,
    mobileBtnBack,
    mobileBtnNext,
    mobileProgressBar,
  };
};

export { getMobileElementsTemplate };
