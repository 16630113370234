import EventHandler from "../../dom/event-handler";
import SelectorEngine from "../../dom/selector-engine";
import { getElementFromSelector, isVisible } from "../../util";
import {
  dropdownCallback,
  tabCallback,
  offcanvasCallback,
  buttonCallback,
  rippleCallback,
  collapseCallback,
  tooltipsCallback,
  popoverCallback,
} from "./free";

const modalCallback = (component, initSelector) => {
  EventHandler.on(
    document,
    `click.twe.${component.NAME}`,
    initSelector,
    function (event) {
      const target = getElementFromSelector(this);

      if (["A", "AREA"].includes(this.tagName)) {
        event.preventDefault();
      }

      EventHandler.one(target, component.EVENT_SHOW, (showEvent) => {
        if (showEvent.defaultPrevented) {
          // only register focus restorer if modal will actually get shown
          return;
        }

        EventHandler.one(target, component.EVENT_HIDDEN, () => {
          if (isVisible(this)) {
            this.focus();
          }
        });
      });

      // avoid conflict when clicking moddal toggler while another one is open
      const allReadyOpen = SelectorEngine.findOne(
        `[${component.OPEN_SELECTOR}="true"]`
      );
      if (allReadyOpen) {
        component.getInstance(allReadyOpen).hide();
      }

      const data = component.getOrCreateInstance(target);

      data.toggle(this);
    }
  );
};

const lightboxCallback = (component, initSelector) => {
  SelectorEngine.find(initSelector).forEach((element) => {
    new component(element);
  });
  EventHandler.on(
    document,
    `click.twe.${component.NAME}.data-api`,
    `${initSelector} img:not([data-twe-lightbox-disabled])`,
    component.toggle()
  );
};

export {
  dropdownCallback,
  tabCallback,
  offcanvasCallback,
  buttonCallback,
  modalCallback,
  rippleCallback,
  collapseCallback,
  tooltipsCallback,
  popoverCallback,
  lightboxCallback,
};
