import { typeCheckConfig, isVisible } from "../../util/index";
import EventHandler from "../../dom/event-handler";
import BaseComponent from "../../base-component";
import Manipulator from "../../dom/manipulator";
import SelectorEngine from "../../dom/selector-engine";
import Stack from "../../util/stack";
import { enableDismissTrigger } from "../../util/component-functions";

/*
------------------------------------------------------------------------
Constants
------------------------------------------------------------------------
*/

const NAME = "alert";
const DATA_KEY = "twe.alert";
const EVENT_KEY = `.${DATA_KEY}`;

const EVENT_CLOSE = `close${EVENT_KEY}`;
const EVENT_CLOSED = `closed${EVENT_KEY}`;

const SELECTOR_ALERT = "[data-twe-alert-init]";
const SHOW_DATA_ATTRIBUTE = "data-twe-alert-show";

const DefaultType = {
  animation: "boolean",
  autohide: "boolean",
  autoclose: "boolean",
  delay: "number",
  position: "(string || null)",
  width: "(string || null)",
  offset: "number",
  stacking: "boolean",
  appendToBody: "boolean",
  container: "(string|null)",
};

const Default = {
  animation: true,
  autohide: false,
  autoclose: false,
  delay: 1000,
  position: null,
  width: null,
  offset: 10,
  stacking: false,
  appendToBody: false,
  container: null,
};

const DefaultClasses = {
  fadeIn:
    "animate-[fade-in_0.3s_both] p-[auto] motion-reduce:transition-none motion-reduce:animate-none",
  fadeOut:
    "animate-[fade-out_0.3s_both] p-[auto] motion-reduce:transition-none motion-reduce:animate-none",
};

const DefaultClassesType = {
  fadeIn: "string",
  fadeOut: "string",
};

/*
------------------------------------------------------------------------
Class Definition
------------------------------------------------------------------------
*/

class Alert extends BaseComponent {
  constructor(element, config, classes) {
    super(element);
    this._element = element;
    this._options = this._getConfig(config);
    this._classes = this._getClasses(classes);
    this._didInit = false;
    this._init();
  }

  // Getters
  static get DefaultType() {
    return DefaultType;
  }

  static get Default() {
    return Default;
  }

  static get NAME() {
    return NAME;
  }

  get verticalOffset() {
    if (!this._options.stacking) return 0;

    return this.stackUtil.calculateOffset();
  }

  get parent() {
    const [parent] = SelectorEngine.parents(
      this._element,
      this._options.container
    );
    return parent;
  }

  get position() {
    const [y, x] = this._options.position.split("-");
    return { y, x };
  }

  // Public

  update(updatedData = {}) {
    if (this._timeout !== null) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }
    this._options = this._getConfig(updatedData);
    this._setup();
  }

  close() {
    const closeEvent = EventHandler.trigger(this._element, EVENT_CLOSE);

    if (closeEvent.defaultPrevented) {
      return;
    }

    let timeout = 0;
    if (this._options.animation) {
      timeout = 300;
      Manipulator.addClass(this._element, this._classes.fadeOut);
    }
    this._element.removeAttribute(SHOW_DATA_ATTRIBUTE);

    setTimeout(() => {
      this._queueCallback(
        () => this._destroyElement(),
        this._element,
        this._options.animation
      );
    }, timeout);
  }

  show() {
    if (!this._element) {
      return;
    }
    if (this._options.autohide) {
      this._setupAutohide();
    }
    if (this._options.autoclose) {
      this._setupAutoclose();
    }

    if (!this._element.hasAttribute(SHOW_DATA_ATTRIBUTE)) {
      Manipulator.removeClass(this._element, "hidden");
      Manipulator.addClass(this._element, "block");

      if (isVisible(this._element)) {
        const handler = (e) => {
          Manipulator.removeClass(this._element, "hidden");
          Manipulator.addClass(this._element, "block");
          EventHandler.off(e.target, "animationend", handler);
        };
        this._element.setAttribute(SHOW_DATA_ATTRIBUTE, "");

        if (this._options.position) {
          this._setupAlignment();
        }

        EventHandler.on(this._element, "animationend", handler);
      }
    }

    if (this._options.animation) {
      Manipulator.removeClass(this._element, this._classes.fadeOut);
      Manipulator.addClass(this._element, this._classes.fadeIn);
    }
  }

  hide() {
    if (!this._element) {
      return;
    }
    if (this._element.hasAttribute(SHOW_DATA_ATTRIBUTE)) {
      this._element.removeAttribute(SHOW_DATA_ATTRIBUTE);
      const handler = (e) => {
        Manipulator.addClass(this._element, "hidden");
        Manipulator.removeClass(this._element, "block");

        if (this._timeout !== null) {
          clearTimeout(this._timeout);
          this._timeout = null;
        }

        if (this._options.stacking) {
          this._updateAlertStack();
        }

        EventHandler.off(e.target, "animationend", handler);
      };

      EventHandler.on(this._element, "animationend", handler);

      Manipulator.removeClass(this._element, this._classes.fadeIn);
      Manipulator.addClass(this._element, this._classes.fadeOut);
    }
  }

  // Private
  _init() {
    if (this._didInit) {
      return;
    }

    enableDismissTrigger(Alert, "close");

    this._didInit = true;
    this._setup();
  }

  _setup() {
    if (this._options.autohide) {
      this._setupAutohide();
    }
    if (this._options.stacking) {
      this._setupStacking();
    }
    if (this._options.width) {
      this._setupWidth();
    }
    if (this._options.appendToBody) {
      this._appendToBody();
    }
    if (!this._options.position) {
      return;
    }

    this._setupAlignment();
    this._setupPosition();
  }

  _setupStacking() {
    this.stackUtil = new Stack(this._element, SELECTOR_ALERT, {
      position: this.position.y,
      offset: this._options.offset,
      container: this._options.container,
      filter: (el) => {
        const instance = Alert.getInstance(el);

        if (!instance) return false;

        return (
          instance._options.container === this._options.container &&
          instance._options.position === this._options.position
        );
      },
    });

    EventHandler.on(this._element, EVENT_CLOSED, () => {
      this._updateAlertStack();
    });
  }

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...(typeof config === "object" && config ? config : {}),
    };

    typeCheckConfig(NAME, config, this.constructor.DefaultType);

    return config;
  }

  _getClasses(classes) {
    const dataAttributes = Manipulator.getDataClassAttributes(this._element);

    classes = {
      ...DefaultClasses,
      ...dataAttributes,
      ...classes,
    };

    typeCheckConfig(NAME, classes, DefaultClassesType);

    return classes;
  }

  _setupAutohide() {
    this._timeout = setTimeout(() => {
      this.hide();
    }, this._options.delay);
  }

  _setupAutoclose() {
    this._timeout = setTimeout(() => {
      this.close();
    }, this._options.delay);
  }

  _setupAlignment() {
    const oppositeY = this.position.y === "top" ? "bottom" : "top";
    const oppositeX = this.position.x === "left" ? "right" : "left";

    if (this.position.x === "center") {
      Manipulator.style(this._element, {
        [this.position.y]: `${this.verticalOffset + this._options.offset}px`,
        [oppositeY]: "unset",
        left: "50%",
        transform: "translate(-50%)",
      });
    } else {
      Manipulator.style(this._element, {
        [this.position.y]: `${this.verticalOffset + this._options.offset}px`,
        [this.position.x]: `${this._options.offset}px`,
        [oppositeY]: "unset",
        [oppositeX]: "unset",
        transform: "unset",
      });
    }
  }

  _setupPosition() {
    if (this._options.container) {
      Manipulator.addClass(this.parent, "relative");
      Manipulator.addClass(this._element, "absolute");
    } else {
      Manipulator.addClass(this._element, "fixed");
    }
  }

  _setupWidth() {
    Manipulator.style(this._element, {
      width: this._options.width,
    });
  }

  _appendToBody() {
    this._element.parentNode.removeChild(this._element);
    document.body.appendChild(this._element);
  }

  _updatePosition() {
    Manipulator.style(this._element, {
      [this.position.y]: `${this.verticalOffset + this._options.offset}px`,
    });
  }

  _updateAlertStack() {
    this.stackUtil.nextElements.forEach((el) => {
      const instance = Alert.getInstance(el);

      if (!instance) {
        return;
      }

      instance._updatePosition();
    });
  }

  _destroyElement() {
    this._element.remove();
    EventHandler.trigger(this._element, EVENT_CLOSED);
    this.dispose();
  }

  // Static

  static jQueryInterface(config) {
    return this.each(function () {
      const data = Alert.getOrCreateInstance(this);

      if (typeof config !== "string") {
        return;
      }

      if (
        data[config] === undefined ||
        config.startsWith("_") ||
        config === "constructor"
      ) {
        throw new TypeError(`No method named "${config}"`);
      }

      data[config](this);
    });
  }
}

export default Alert;
