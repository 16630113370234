import { createPopper } from "@popperjs/core";
import {
  element,
  typeCheckConfig,
  getUID,
  getTransitionDurationFromElement,
} from "../../util/index";
import Data from "../../dom/data";
import EventHandler from "../../dom/event-handler";
import SelectorEngine from "../../dom/selector-engine";
import Manipulator from "../../dom/manipulator";
import { ESCAPE } from "../../util/keycodes";
import Ripple from "../../free/methods/ripple";
import FocusTrap from "../../util/focusTrap";
import ScrollBarHelper from "../../util/scrollbar";
import BaseComponent from "../../base-component";

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = "popconfirm";
const DATA_KEY = "twe.popconfirm";
const EVENT_KEY = `.${DATA_KEY}`;
const EVENT_CANCEL = `cancel${EVENT_KEY}`;
const EVENT_CONFIRM = `confirm${EVENT_KEY}`;
const SELECTOR_ATTR_POPCONFIRM_BODY = "[data-twe-popconfirm-body]";
const ATTR_POPCONFIRM_POPOVER = "data-twe-popconfirm-popover";
const ATTR_POPCONFIRM_MODAL = "data-twe-popconfirm-modal";
const ATTR_POPCONFIRM_BACKDROP = "data-twe-popconfirm-backdrop";

const DefaultType = {
  popconfirmMode: "string",
  message: "string",
  cancelText: "(null|string)",
  okText: "(null|string)",
  popconfirmIconTemplate: "string",
  cancelLabel: "(null|string)",
  confirmLabel: "(null|string)",
  position: "(null|string)",
};

const Default = {
  popconfirmMode: "inline",
  message: "Are you sure?",
  cancelText: "Cancel",
  okText: "OK",
  popconfirmIconTemplate: ``,
  cancelLabel: "Cancel",
  confirmLabel: "Confirm",
  position: "bottom",
};

const DefaultClassesType = {
  backdrop: "string",
  body: "string",
  btnCancel: "string",
  btnConfirm: "string",
  btnsContainer: "string",
  fade: "string",
  icon: "string",
  message: "string",
  messageText: "string",
  modal: "string",
  popover: "string",
};

const DefaultClasses = {
  backdrop:
    "h-full w-full z-[1070] fixed top-0 left-0 bg-black/40 flex justify-center items-center",
  body: "p-4 bg-white rounded-lg opacity-0 dark:bg-surface-dark",
  btnCancel:
    "inline-block rounded bg-primary-100 px-4 pb-[5px] pt-1.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-200 focus:bg-primary-accent-200 focus:outline-none focus:ring-0 active:bg-primary-accent-200 motion-reduce:transition-none dark:bg-primary-300 dark:hover:bg-primary-400 dark:focus:bg-primary-400 dark:active:bg-primary-400",
  btnConfirm:
    "inline-block rounded bg-primary me-1 px-4 pb-[5px] pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong",
  btnsContainer: "flex justify-end space-x-2 rtl:space-x-reverse",
  fade: "transition-opacity duration-[150ms] ease-linear",
  icon: "pe-2",
  message: "flex mb-3",
  messageText: "text-neutral-600 dark:text-white",
  modal: "absolute w-[300px] z-[1080] shadow-md rounded-lg",
  popover: "w-[300px] border-0 rounded-lg z-[1080] shadow-md",
};

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Popconfirm extends BaseComponent {
  constructor(element, options, classes) {
    super(element);
    this._element = element;
    this._options = this._getConfig(options);
    this._classes = this._getClasses(classes);
    this._popper = null;
    this._cancelButton = "";
    this._confirmButton = "";
    this._isOpen = false;
    this._uid = this._element.id
      ? `popconfirm-${this._element.id}`
      : getUID("popconfirm-");
    this._focusTrap = null;
    this._scrollBar = new ScrollBarHelper();

    this._clickHandler = this.open.bind(this);
    this._escapeKeydownHandler = this._handleEscapeKey.bind(this);
    this._outsideClickHandler = this._handleOutsideClick.bind(this);

    EventHandler.on(this._element, "click", this._clickHandler);
  }

  // Getters

  static get NAME() {
    return NAME;
  }

  get container() {
    return SelectorEngine.findOne(`#${this._uid}`);
  }

  get popconfirmBody() {
    return SelectorEngine.findOne(
      SELECTOR_ATTR_POPCONFIRM_BODY,
      this.container
    );
  }

  // Public

  dispose() {
    let transitionTime = 0;

    if (this._isOpen || this.container !== null) {
      transitionTime = getTransitionDurationFromElement(this.popconfirmBody);
      this.close();
    }
    EventHandler.off(this._element, "click", this._clickHandler);

    setTimeout(() => {
      super.dispose();
    }, transitionTime);
  }

  open() {
    if (this._isOpen) {
      return;
    }
    if (this._options.popconfirmMode === "inline") {
      this._openPopover(this._getPopoverTemplate());
    } else {
      this._openModal(this._getModalTemplate());
      this._scrollBar.hide();
    }
    this._handleCancelButtonClick();
    this._handleConfirmButtonClick();
    this._listenToEscapeKey();
    this._listenToOutsideClick();
  }

  close() {
    if (!this._isOpen) {
      return;
    }
    if (
      this._popper !== null ||
      SelectorEngine.findOne(`[${ATTR_POPCONFIRM_POPOVER}]`) !== null
    ) {
      EventHandler.on(
        this.popconfirmBody,
        "transitionend",
        this._handlePopconfirmTransitionEnd.bind(this)
      );
      Manipulator.removeClass(this.popconfirmBody, "opacity-100");
    } else {
      const tempElement = SelectorEngine.findOne(
        `[${ATTR_POPCONFIRM_BACKDROP}]`
      );
      Manipulator.removeClass(this.popconfirmBody, "opacity-100");
      document.body.removeChild(tempElement);
      this._isOpen = false;
    }

    this._removeFocusTrap();
    this._scrollBar.reset();

    this._element.focus();

    EventHandler.off(document, "click", this._outsideClickHandler);
    EventHandler.off(document, "keydown", this._escapeKeydownHandler);
  }

  _setFocusTrap(element) {
    this._focusTrap = new FocusTrap(element, {
      event: "keydown",
      condition: (event) => event.key === "Tab",
    });

    this._focusTrap.trap();

    const cancelButton = SelectorEngine.findOne(
      "#popconfirm-button-cancel",
      element
    );
    const confirmButton = SelectorEngine.findOne(
      "#popconfirm-button-confirm",
      element
    );

    if (cancelButton) {
      cancelButton.focus();
    } else {
      confirmButton.focus();
    }
  }

  _removeFocusTrap() {
    if (this._focusTrap) {
      this._focusTrap.disable();
      this._focusTrap = null;
    }
  }

  _handlePopconfirmTransitionEnd(event) {
    if (event.target !== this.popconfirmBody) {
      return;
    }

    const popoverTemplate = SelectorEngine.findOne(
      `[${ATTR_POPCONFIRM_POPOVER}]`
    );
    EventHandler.off(this.popconfirmBody, "transitionend");

    if (this._isOpen && event && event.propertyName === "opacity") {
      this._popper.destroy();

      if (popoverTemplate) {
        document.body.removeChild(popoverTemplate);
      }

      this._isOpen = false;
    }
  }

  // Private

  _getPopoverTemplate() {
    const popover = element("div");
    const popconfirmTemplate = this._getPopconfirmTemplate();
    popover.setAttribute(ATTR_POPCONFIRM_POPOVER, "");
    Manipulator.addClass(popover, this._classes.popover);
    popover.id = this._uid;
    popover.innerHTML = popconfirmTemplate;
    return popover;
  }

  _getModalTemplate() {
    const modal = element("div");
    const popconfirmTemplate = this._getPopconfirmTemplate();
    modal.setAttribute(ATTR_POPCONFIRM_MODAL, "");
    Manipulator.addClass(modal, `${this._classes.modal}`);
    modal.id = this._uid;
    modal.innerHTML = popconfirmTemplate;
    return modal;
  }

  _getPopconfirmTemplate() {
    return `<div data-twe-popconfirm-body class="${this._classes.body}">
      <p class="${this._classes.message}">
      ${
        this._options.popconfirmIconTemplate
          ? `<span class="${this._classes.icon}">${this._options.popconfirmIconTemplate}</span>`
          : ""
      }
      <span class="${this._classes.messageText}">${this._options.message}</span>
      </p>
      <div class="${this._classes.btnsContainer}">
      ${
        this._options.cancelText
          ? `<button type="button" data-twe-ripple-init data-twe-ripple-color="light" id="popconfirm-button-cancel" aria-label="${this._options.cancelLabel}"
        class="${this._classes.btnCancel}">${this._options.cancelText}</button>`
          : ""
      }
      <button type="button" data-twe-ripple-init data-twe-ripple-color="light" id="popconfirm-button-confirm"
      aria-label="${this._options.confirmLabel}"
      class="${this._classes.btnConfirm}">${
      this._options.okText ? this._options.okText : "Ok"
    }</button>
      </div>
    </div>`;
  }

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...config,
    };
    typeCheckConfig(NAME, config, DefaultType);
    return config;
  }

  _getClasses(classes) {
    const dataAttributes = Manipulator.getDataClassAttributes(this._element);

    classes = {
      ...DefaultClasses,
      ...dataAttributes,
      ...classes,
    };

    typeCheckConfig(NAME, classes, DefaultClassesType);

    return classes;
  }

  _openPopover(template) {
    this._popper = createPopper(this._element, template, {
      placement: this._translatePositionValue(),
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 5],
          },
        },
      ],
    });
    document.body.appendChild(template);

    setTimeout(() => {
      Manipulator.addClass(
        this.popconfirmBody,
        `${this._classes.fade} opacity-100`
      );
      this._isOpen = true;

      this._setFocusTrap(this.container);
    }, 0);
  }

  _openModal(template) {
    const backdrop = element("div");
    backdrop.setAttribute(ATTR_POPCONFIRM_BACKDROP, "");
    Manipulator.addClass(backdrop, this._classes.backdrop);
    document.body.appendChild(backdrop);
    backdrop.appendChild(template);
    Manipulator.addClass(this.popconfirmBody, "opacity-100");
    this._isOpen = true;

    this._setFocusTrap(this.container);
  }

  _handleCancelButtonClick() {
    const container = this.container;

    this._cancelButton = SelectorEngine.findOne(
      "#popconfirm-button-cancel",
      container
    );

    Ripple.getOrCreateInstance(this._cancelButton, { rippleColor: "light" });
    if (this._cancelButton !== null) {
      EventHandler.on(this._cancelButton, "click", () => {
        this.close();
        EventHandler.trigger(this._element, EVENT_CANCEL);
      });
    }
  }

  _handleConfirmButtonClick() {
    const container = this.container;
    this._confirmButton = SelectorEngine.findOne(
      "#popconfirm-button-confirm",
      container
    );

    Ripple.getOrCreateInstance(this._confirmButton, { rippleColor: "light" });
    EventHandler.on(this._confirmButton, "click", () => {
      this.close();
      EventHandler.trigger(this._element, EVENT_CONFIRM);
    });
  }

  _listenToEscapeKey() {
    EventHandler.on(document, "keydown", this._escapeKeydownHandler);
  }

  _handleEscapeKey(event) {
    if (event.keyCode === ESCAPE) {
      if (this._isOpen) {
        EventHandler.trigger(this._element, EVENT_CANCEL);
      }
      this.close();
    }
  }

  _listenToOutsideClick() {
    EventHandler.on(document, "click", this._outsideClickHandler);
  }

  _handleOutsideClick(event) {
    const container = this.container;
    const isContainer = event.target === container;
    const isContainerContent = container && container.contains(event.target);
    const isElement = event.target === this._element;
    const isElementContent =
      this._element && this._element.contains(event.target);
    if (
      !isContainer &&
      !isContainerContent &&
      !isElement &&
      !isElementContent
    ) {
      if (this._isOpen) {
        EventHandler.trigger(this._element, EVENT_CANCEL);
      }
      this.close();
    }
  }

  _translatePositionValue() {
    switch (this._options.position) {
      // left, right as default
      case "top left":
        return "top-end";
      case "top":
        return "top";
      case "top right":
        return "top-start";
      case "bottom left":
        return "bottom-end";
      case "bottom":
        return "bottom";
      case "bottom right":
        return "bottom-start";
      case "left":
        return "left";
      case "left top":
        return "left-end";
      case "left bottom":
        return "left-start";
      case "right":
        return "right";
      case "right top":
        return "right-end";
      case "right bottom":
        return "right-start";
      case undefined:
        return "bottom";
      default:
        return "bottom";
    }
  }

  // Static

  static jQueryInterface(config, options) {
    return this.each(function () {
      const data = Data.getData(this, DATA_KEY);
      const _config = typeof config === "object" && config;

      if (!data && /dispose/.test(config)) {
        return;
      }

      if (!data) {
        // eslint-disable-next-line consistent-return
        return new Popconfirm(this, _config);
      }

      if (typeof config === "string") {
        if (typeof data[config] === "undefined") {
          throw new TypeError(`No method named "${config}"`);
        }

        data[config](options);
      }
    });
  }
}

export default Popconfirm;
