export const getTimepickerTemplate = (
  {
    format24,
    okLabel,
    cancelLabel,
    headID,
    footerID,
    bodyID,
    pickerID,
    clearLabel,
    inline,
    showClearBtn,
    amLabel,
    pmLabel,
  },
  classes
) => {
  const normalTemplate = `<div id='${pickerID}' class='${
    classes.timepickerWrapper
  }' data-twe-timepicker-wrapper>
      <div class="${classes.timepickerContainer}">
        <div class="${
          classes.timepickerElements
        }" data-twe-timepicker-elements-wrapper>
        <div id='${headID}' class='${
    classes.timepickerHead
  }' style='padding-right:${format24 ? 50 : 10}px'>
        <div class='${classes.timepickerHeadContent}'>
            <div class="${classes.timepickerCurrentWrapper}">
              <span class="${classes.timepickerCurrentButtonWrapper}">
                <button type='button' class='${
                  classes.timepickerCurrentButton
                }' tabindex="0" data-twe-timepicker-active data-twe-timepicker-current data-twe-timepicker-hour>21</button>
              </span>
              <button type='button' class='${
                classes.timepickerDot
              }' disabled>:</button>
            <span class="${classes.timepickerCurrentButtonWrapper}">
              <button type='button' class='${
                classes.timepickerCurrentButton
              }' tabindex="0" data-twe-timepicker-current data-twe-timepicker-minute>21</button>
            </span>
            </div>
            ${
              !format24
                ? `<div class="${classes.timepickerModeWrapper}">
                  <button type='button' class="${classes.timepickerModeAm}" tabindex="0" data-twe-timepicker-am data-twe-timepicker-hour-mode>${amLabel}</button>
                  <button class="${classes.timepickerModePm}" tabindex="0" data-twe-timepicker-pm data-twe-timepicker-hour-mode>${pmLabel}</button>
                </div>`
                : ""
            }
        </div>
      </div>
      ${
        !inline
          ? `<div id='${bodyID}' class='${
              classes.timepickerClockWrapper
            }' data-twe-timepicker-clock-wrapper>
            <div class='${classes.timepickerClock}' data-twe-timepicker-clock>
              <span class='${
                classes.timepickerMiddleDot
              }' data-twe-timepicker-middle-dot></span>
              <div class='${
                classes.timepickerHandPointer
              }' data-twe-timepicker-hand-pointer>
                <div class='${
                  classes.timepickerPointerCircle
                }' data-twe-timepicker-circle></div>
              </div>
              ${
                format24
                  ? '<div class="' +
                    classes.timepickerClockInner +
                    '" data-twe-timepicker-clock-inner></div>'
                  : ""
              }
            </div>
          </div>`
          : ""
      }
    </div>
    <div id='${footerID}' class='${classes.timepickerFooterWrapper}'>
      <div class="${classes.timepickerFooter}">
        ${
          showClearBtn
            ? `<button type='button' class='${classes.timepickerFooterButton}' data-twe-timepicker-clear tabindex="0">${clearLabel}</button>`
            : ""
        }
        <button type='button' class='${
          classes.timepickerFooterButton
        }' data-twe-timepicker-cancel tabindex="0">${cancelLabel}</button>
        <button type='button' class='${
          classes.timepickerFooterButton
        }' data-twe-timepicker-submit tabindex="0">${okLabel}</button>
      </div>
    </div>
  </div>
</div>`;

  const inlineTemplate = `<div id='${pickerID}' class='${
    classes.timepickerInlineWrapper
  }' data-twe-timepicker-wrapper>
        <div class="${classes.timepickerInlineContainer}">
          <div class="${
            classes.timepickerInlineElements
          }" data-twe-timepicker-elements-wrapper>
          <div id='${headID}' class='${classes.timepickerInlineHead}'
          style='padding-right:10px'>
          <div class='${classes.timepickerInlineHeadContent}'>
              <div class="${classes.timepickerCurrentWrapper}">
                <span class="${
                  classes.timepickerInlineHourWrapper
                }" data-twe-timepicker-inline-hour-icons>
                  <span class="${
                    classes.timepickerInlineIconUp
                  }" data-twe-timepicker-icon-up data-twe-timepicker-icon-inline-hour>
                    <span class="${classes.timepickerInlineIconSvg}">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>   
                    </span>
                  </span>
                  <button type='button' class='${
                    classes.timepickerInlineCurrentButton
                  }' data-twe-timepicker-hour data-twe-timepicker-current data-twe-timepicker-current-inline tabindex="0">21</button>
                  <span class="${
                    classes.timepickerInlineIconDown
                  }" data-twe-timepicker-icon-inline-hour data-twe-timepicker-icon-down>
                    <span class="${classes.timepickerInlineIconSvg}">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>  
                    </span>
                  </span>
                </span>
                <button type='button' class='${
                  classes.timepickerInlineDot
                }' data-twe-timepicker-current-inline disabled>:</button>
              <span class="${classes.timepickerCurrentMinuteWrapper}">
                <span class="${
                  classes.timepickerInlineIconUp
                }" data-twe-timepicker-icon-up data-twe-timepicker-icon-inline-minute>
                  <span class="${classes.timepickerInlineIconSvg}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg>
                  </span>
                </span>
                <button type='button' class='${
                  classes.timepickerInlineCurrentButton
                }' data-twe-timepicker-minute data-twe-timepicker-current data-twe-timepicker-current-inline tabindex="0">21</button>
                <span class="${
                  classes.timepickerInlineIconDown
                }" data-twe-timepicker-icon-inline-minute data-twe-timepicker-icon-down>
                  <span class="${classes.timepickerInlineIconSvg}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg> 
                  </span>
                </span>
              </span>
              </div>
              ${
                !format24
                  ? `<div class="${classes.timepickerInlineModeWrapper}">
                      <button type='button' class="${classes.timepickerInlineModeAm}" data-twe-timepicker-am data-twe-timepicker-hour-mode tabindex="0">${amLabel}</button>
                      <button class="${classes.timepickerInlineModePm}" data-twe-timepicker-hour-mode data-twe-timepicker-pm tabindex="0">${pmLabel}</button>
                      <button type='button' class='${classes.timepickerInlineSubmitButton}' data-twe-timepicker-submit tabindex="0">${okLabel}</button>
                    </div>`
                  : ""
              }
              ${
                format24
                  ? `<button class='${classes.timepickerInlineSubmitButton}' data-twe-timepicker-submit tabindex="0">${okLabel}</button>`
                  : ""
              }
          </div>
        </div>
      </div>
    </div>
</div>`;
  return inline ? inlineTemplate : normalTemplate;
};

export const getToggleButtonTemplate = (options, id, classes) => {
  const { iconSVG } = options;

  return `
  <button id="${id}" tabindex="0" type="button" class="${classes.timepickerToggleButton}" data-twe-toggle="timepicker" data-twe-timepicker-toggle-button data-twe-timepicker-icon>
    ${iconSVG}
  </button>
`;
};
