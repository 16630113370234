import { typeCheckConfig } from "../../util/index";
import EventHandler from "../../dom/event-handler";
import SelectorEngine from "../../dom/selector-engine";
import Manipulator from "../../dom/manipulator";
import Tooltip from "../../free/components/tooltip";
import BaseComponent from "../../base-component";
import Data from "../../dom/data";

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = "rating";
const DATA_KEY = "twe.rating";
const DATA_INIT = "data-twe-rating-init";
const SELECTOR_ICON = "[data-twe-rating-icon-ref]";
const EVENT_KEY = `.${DATA_KEY}`;

const ARROW_LEFT_KEY = "ArrowLeft";
const ARROW_RIGHT_KEY = "ArrowRight";

const DefaultType = {
  tooltip: "string",
  value: "(string|number)",
  readonly: "boolean",
  after: "string",
  before: "string",
  dynamic: "boolean",
  active: "string",
};

const Default = {
  tooltip: "top",
  value: "",
  readonly: false,
  after: "",
  before: "",
  dynamic: false,
  active: "fill-current",
};

const EVENT_SELECT = `scoreSelect${EVENT_KEY}`;
const EVENT_HOVER = `scoreHover${EVENT_KEY}`;
const EVENT_KEYUP = `keyup${EVENT_KEY}`;
const EVENT_FOCUSOUT = `focusout${EVENT_KEY}`;
const EVENT_KEYDOWN = `keydown${EVENT_KEY}`;
const EVENT_MOUSEDOWN = `mousedown${EVENT_KEY}`;

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Rating extends BaseComponent {
  constructor(element, options) {
    super(element);
    this._element = element;
    this._icons = SelectorEngine.find(SELECTOR_ICON, this._element);
    this._options = this._getConfig(options);
    this._index = -1;
    this._savedIndex = null;
    this._originalClassList = [];
    this._originalIcons = [];
    this._fn = {};
    this._tooltips = [];

    if (this._element) {
      this._init();
    }
  }

  // Getters
  static get NAME() {
    return NAME;
  }

  dispose() {
    if (!this._options.readonly) {
      EventHandler.off(this._element, EVENT_KEYUP);
      EventHandler.off(this._element, EVENT_FOCUSOUT);
      EventHandler.off(this._element, EVENT_KEYDOWN);
      this._element.removeEventListener("mouseleave", this._fn.mouseleave);

      this._icons.forEach((el, i) => {
        EventHandler.off(el, EVENT_MOUSEDOWN);
        el.removeEventListener("mouseenter", this._fn.mouseenter[i]);
        Manipulator.removeClass(el, "cursor-pointer");
      });

      this._tooltips.forEach((el) => {
        el._element.removeAttribute(DATA_INIT);
        el.dispose();
      });

      this._icons.forEach((el) => el.removeAttribute("tabIndex"));
    }

    super.dispose();
  }

  // Private
  _init() {
    if (!this._options.readonly) {
      this._bindMouseEnter();
      this._bindMouseLeave();
      this._bindMouseDown();
      this._bindKeyDown();
      this._bindKeyUp();
      this._bindFocusLost();

      this._icons.forEach((el) => {
        Manipulator.addClass(el, "cursor-pointer");
      });
    }

    if (this._options.dynamic) {
      this._saveOriginalClassList();
      this._saveOriginalIcons();
    }

    this._setCustomText();
    this._setToolTips();

    if (this._options.value) {
      this._index = this._options.value - 1;
      this._updateRating(this._index);
    }
  }

  _getConfig(config) {
    const dataAttributes = Manipulator.getDataAttributes(this._element);

    config = {
      ...Default,
      ...dataAttributes,
      ...config,
    };

    typeCheckConfig(NAME, config, DefaultType);

    return config;
  }

  _bindMouseEnter() {
    this._fn.mouseenter = [];
    this._icons.forEach((el, i) => {
      // EventHandler.on changes mouseenter to mouseover - use addEventListener
      el.addEventListener(
        "mouseenter",
        // this._fn.mouseenter[i] is needed to create reference and unpin events after call dispose
        // prettier-ignore
        this._fn.mouseenter[i] = (e) => {
          this._index = this._icons.indexOf(e.target);
          this._updateRating(this._index);
          this._triggerEvents(el, EVENT_HOVER);
          // prettier-ignore
        }
      );
    });
  }

  _bindMouseLeave() {
    // EventHandler.on changes mouseleave to mouseout - use addEventListener
    this._element.addEventListener(
      "mouseleave",
      // this._fn.mouseleave is needed to create reference and unpin events after call dispose
      // prettier-ignore
      this._fn.mouseleave = () => {
        if (this._savedIndex !== null) {
          this._updateRating(this._savedIndex);
          this._index = this._savedIndex;
        } else if (this._options.value) {
          this._updateRating(this._options.value - 1);
          this._index = this._options.value - 1
        } else {
          this._index = -1;
          this._clearRating();
        }
        // prettier-ignore
      }
    );
  }

  _bindMouseDown() {
    this._icons.forEach((el) => {
      EventHandler.on(el, EVENT_MOUSEDOWN, () => {
        this._setElementOutline("none");
        this._savedIndex = this._index;
        this._triggerEvents(el, EVENT_SELECT);
      });
    });
  }

  _bindKeyDown() {
    this._element.tabIndex = 0;
    EventHandler.on(this._element, EVENT_KEYDOWN, (e) =>
      this._updateAfterKeyDown(e)
    );
  }

  _bindKeyUp() {
    EventHandler.on(this._element, EVENT_KEYUP, () =>
      this._setElementOutline("auto")
    );
  }

  _bindFocusLost() {
    EventHandler.on(this._element, EVENT_FOCUSOUT, () =>
      this._setElementOutline("none")
    );
  }

  _setElementOutline(value) {
    this._element.style.outline = value;
  }

  _triggerEvents(el, event) {
    EventHandler.trigger(el, event, {
      value: this._index + 1,
    });
  }

  _updateAfterKeyDown(e) {
    const maxIndex = this._icons.length - 1;
    const indexBeforeChange = this._index;

    if (e.key === ARROW_RIGHT_KEY && this._index < maxIndex) {
      this._index += 1;
    }

    if (e.key === ARROW_LEFT_KEY && this._index > -1) {
      this._index -= 1;
    }

    if (indexBeforeChange !== this._index) {
      this._savedIndex = this._index;
      this._updateRating(this._savedIndex);
      this._triggerEvents(this._icons[this._savedIndex], EVENT_SELECT);
    }
  }

  _updateRating(index) {
    this._clearRating();

    if (this._options.dynamic) {
      this._restoreOriginalIcon(index);
    }

    this._icons.forEach((el, i) => {
      if (i <= index) {
        Manipulator.addClass(el.querySelector("svg"), this._options.active);
      }
    });
  }

  _clearRating() {
    this._icons.forEach((el, i) => {
      const element = el.querySelector("svg");
      if (this._options.dynamic) {
        el.classList = this._originalClassList[i];
        element.innerHTML = this._originalIcons[i];
      }
      Manipulator.removeClass(element, this._options.active);
    });
  }

  _setToolTips() {
    this._icons.forEach((el, i) => {
      const hasOwnTooltips = Manipulator.getDataAttribute(el, "toggle");

      if (el.title && !hasOwnTooltips) {
        Manipulator.setDataAttribute(el, "toggle", "tooltip");
        this._tooltips[i] = new Tooltip(el, {
          placement: this._options.tooltip,
        });
      }
    });
  }

  _setCustomText() {
    this._icons.forEach((el) => {
      const after = Manipulator.getDataAttribute(el, "after");
      const before = Manipulator.getDataAttribute(el, "before");

      if (after) {
        el.insertAdjacentHTML("afterEnd", after);
      }

      if (before) {
        el.insertAdjacentHTML("beforeBegin", before);
      }
    });
  }

  _saveOriginalClassList() {
    this._icons.forEach((el) => {
      const classList = el.classList.value;
      this._originalClassList.push(classList);
    });
  }

  _saveOriginalIcons() {
    this._icons.forEach((el) => {
      const svgHtml = el.querySelector("svg").innerHTML;
      this._originalIcons.push(svgHtml);
    });
  }

  _restoreOriginalIcon(index) {
    const classList = this._originalClassList[index];
    const icon = this._originalIcons[index];
    this._icons.forEach((el, i) => {
      if (i <= index) {
        const element = el.querySelector("svg");
        element.innerHTML = icon;
        el.classList = classList;
      }
    });
  }

  // Static

  static jQueryInterface(config, options) {
    return this.each(function () {
      let data = Data.getData(this, DATA_KEY);
      const _config = typeof config === "object" && config;

      if (!data && /dispose|hide/.test(config)) {
        return;
      }

      if (!data) {
        data = new Rating(this, _config);
      }

      if (typeof config === "string") {
        if (typeof data[config] === "undefined") {
          throw new TypeError(`No method named "${config}"`);
        }

        data[config](options);
      }
    });
  }
}

export default Rating;
