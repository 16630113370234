import { reflow, typeCheckConfig } from "../../util/index";
import EventHandler from "../../dom/event-handler";
import Manipulator from "../../dom/manipulator";
import BaseComponent from "../../base-component";
import SelectorEngine from "../../dom/selector-engine";
import Stack from "../../util/stack";
import { enableDismissTrigger } from "../../util/component-functions";

/*
------------------------------------------------------------------------
Constants
------------------------------------------------------------------------
*/

const NAME = "toast";
const DATA_KEY = "twe.toast";
const EVENT_KEY = `.${DATA_KEY}`;

const EVENT_MOUSEOVER = `mouseover${EVENT_KEY}`;
const EVENT_MOUSEOUT = `mouseout${EVENT_KEY}`;
const EVENT_FOCUSIN = `focusin${EVENT_KEY}`;
const EVENT_FOCUSOUT = `focusout${EVENT_KEY}`;
const EVENT_HIDE = `hide${EVENT_KEY}`;
const EVENT_HIDDEN = `hidden${EVENT_KEY}`;
const EVENT_SHOW = `show${EVENT_KEY}`;
const EVENT_SHOWN = `shown${EVENT_KEY}`;
const EVENT_CLOSED = `closed${EVENT_KEY}`;

const HIDE_DATA_ATTRIBUTE = "data-twe-toast-hide";
const SHOW_DATA_ATTRIBUTE = "data-twe-toast-show";
const SHOWING_DATA_ATTRIBUTE = "data-twe-toast-showing";
const SELECTOR_TOAST = "[data-twe-toast-init";

const DefaultType = {
  animation: "boolean",
  autohide: "boolean",
  delay: "(boolean|number)",
  position: "(string|null)",
  width: "(string || null)",
  offset: "number",
  appendToBody: "boolean",
  stacking: "boolean",
  container: "(string|null)",
};

const Default = {
  animation: true,
  autohide: false,
  position: null,
  width: null,
  delay: 1000,
  offset: 10,
  appendToBody: false,
  stacking: true,
  container: null,
};

const DefaultClasses = {
  fadeIn:
    "animate-[fade-in_0.3s_both] p-[auto] motion-reduce:transition-none motion-reduce:animate-none",
  fadeOut:
    "animate-[fade-out_0.3s_both] p-[auto] motion-reduce:transition-none motion-reduce:animate-none",
};

const DefaultClassesType = {
  fadeIn: "string",
  fadeOut: "string",
};

/*
------------------------------------------------------------------------
Class Definition
------------------------------------------------------------------------
*/

class Toast extends BaseComponent {
  constructor(element, config, classes) {
    super(element);

    this._config = this._getConfig(config);
    this._classes = this._getClasses(classes);
    this._timeout = null;
    this._hasMouseInteraction = false;
    this._hasKeyboardInteraction = false;
    this._setListeners();
    this._setup();
  }

  // Getters

  static get DefaultType() {
    return DefaultType;
  }

  static get Default() {
    return Default;
  }

  static get NAME() {
    return NAME;
  }

  get parent() {
    const [parent] = SelectorEngine.parents(
      this._element,
      this._config.container
    );
    return parent;
  }

  get position() {
    if (!this._config.position) return null;
    const [y, x] = this._config.position.split("-");
    return { y, x };
  }

  get verticalOffset() {
    if (!this._config.stacking || !this.position) return 0;

    return this.stackUtil.calculateOffset();
  }

  // Public

  update(updatedData = {}) {
    this._config = this._getConfig(updatedData);

    if (!this._config.position) {
      return;
    }

    if (this._config.stacking) {
      this._setupStacking();

      EventHandler.on(this._element, EVENT_HIDDEN, () => {
        setTimeout(() => this._updateToastStack(), 150);
      });
    }

    this._setupPosition();
    this._setupAlignment();
  }

  show() {
    const showEvent = EventHandler.trigger(this._element, EVENT_SHOW);

    if (showEvent.defaultPrevented) {
      return;
    }

    this._clearTimeout();

    if (this._config.animation) {
      Manipulator.removeClass(this._element, this._classes.fadeOut);
      Manipulator.addClass(this._element, this._classes.fadeIn);
    }

    const complete = () => {
      this._element.removeAttribute(SHOWING_DATA_ATTRIBUTE);
      EventHandler.trigger(this._element, EVENT_SHOWN);

      this._maybeScheduleHide();
    };

    this._element.removeAttribute(HIDE_DATA_ATTRIBUTE);
    reflow(this._element);
    this._element.setAttribute(SHOW_DATA_ATTRIBUTE, "");
    this._element.setAttribute(SHOWING_DATA_ATTRIBUTE, "");

    this._queueCallback(complete, this._element, this._config.animation);
  }

  hide() {
    if (!this._element || this._element.dataset.tweToastShow === undefined) {
      return;
    }

    const hideEvent = EventHandler.trigger(this._element, EVENT_HIDE);

    if (hideEvent.defaultPrevented) {
      return;
    }

    const complete = () => {
      let timeout = 0;
      if (this._config.animation) {
        timeout = 300;
        Manipulator.removeClass(this._element, this._classes.fadeIn);
        Manipulator.addClass(this._element, this._classes.fadeOut);
      }
      setTimeout(() => {
        this._element.setAttribute(HIDE_DATA_ATTRIBUTE, "");
        this._element.removeAttribute(SHOWING_DATA_ATTRIBUTE);
        this._element.removeAttribute(SHOW_DATA_ATTRIBUTE);
        EventHandler.trigger(this._element, EVENT_HIDDEN);
      }, timeout);
    };

    this._element.setAttribute(SHOWING_DATA_ATTRIBUTE, "");
    this._queueCallback(complete, this._element, this._config.animation);
  }

  dispose() {
    this._clearTimeout();

    if (this._element.dataset.tweToastShow !== undefined) {
      this._element.removeAttribute(SHOW_DATA_ATTRIBUTE);
    }

    EventHandler.off(this._element, EVENT_SHOW);
    EventHandler.off(this._element, EVENT_SHOWN);
    EventHandler.off(this._element, EVENT_HIDE);
    EventHandler.off(this._element, EVENT_HIDDEN);

    super.dispose();
  }

  // Private

  _setup() {
    if (this._didInit) {
      return;
    }

    enableDismissTrigger(Toast);

    if (this._config.width) {
      this._setupWidth();
    }
    if (!this._config.position) {
      return;
    }
    if (this._config.stacking) {
      this._setupStacking();

      EventHandler.on(this._element, EVENT_HIDDEN, () => {
        setTimeout(() => this._updateToastStack(), 150);
      });
    }

    this._setupPosition();
    this._setupDisplay();

    if (!this._config.container && this._config.appendToBody) {
      this._appendToBody();
    }

    this._didInit = true;
  }

  _setupStacking() {
    this.stackUtil = new Stack(this._element, SELECTOR_TOAST, {
      position: this.position.y,
      offset: this._config.offset,
      container: this._config.container,
      filter: (el) => {
        const instance = Toast.getInstance(el);

        if (!instance) return false;

        return (
          instance._config.container === this._config.container &&
          instance._config.position === this._config.position
        );
      },
    });

    EventHandler.on(this._element, EVENT_CLOSED, () => {
      this._updateToastStack();
    });
  }

  _setupWidth() {
    Manipulator.style(this._element, {
      width: this._config.width,
    });
  }

  _setupPosition() {
    if (this._config.container) {
      Manipulator.addClass(this.parent, "relative");
      Manipulator.addClass(this._element, "absolute");
    } else {
      Manipulator.addClass(this._element, "fixed");
    }
  }

  _setupAlignment() {
    const oppositeY = this.position.y === "top" ? "bottom" : "top";
    const oppositeX = this.position.x === "left" ? "right" : "left";
    if (this.position.x === "center") {
      Manipulator.style(this._element, {
        [this.position.y]: `${this.verticalOffset + this._config.offset}px`,
        [oppositeY]: "unset",
        left: "50%",
        transform: "translate(-50%)",
      });
    } else {
      Manipulator.style(this._element, {
        [this.position.y]: `${this.verticalOffset + this._config.offset}px`,
        [this.position.x]: `${this._config.offset}px`,
        [oppositeY]: "unset",
        [oppositeX]: "unset",
        transform: "unset",
      });
    }
  }

  _setupDisplay() {
    if (!this._element.getAttribute("data-twe-toast-show")) {
      Manipulator.style(this._element, {
        display: "none",
      });
    }

    EventHandler.on(this._element, EVENT_HIDDEN, () => {
      Manipulator.style(this._element, {
        display: "none",
      });
    });

    EventHandler.on(this._element, EVENT_SHOW, () => {
      this._setupAlignment();
      Manipulator.style(this._element, {
        display: "block",
      });
    });
  }

  _appendToBody() {
    this._element.parentNode.removeChild(this._element);
    document.body.appendChild(this._element);
  }

  _updatePosition() {
    Manipulator.style(this._element, {
      [this.position.y]: `${this.verticalOffset + this._config.offset}px`,
    });
  }

  _updateToastStack() {
    this.stackUtil.nextElements.forEach((el) => {
      const instance = Toast.getInstance(el);

      if (!instance) {
        return;
      }

      instance._updatePosition();
    });
  }

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...(typeof config === "object" && config ? config : {}),
    };

    typeCheckConfig(NAME, config, this.constructor.DefaultType);

    return config;
  }

  _getClasses(classes) {
    const dataAttributes = Manipulator.getDataClassAttributes(this._element);

    classes = {
      ...DefaultClasses,
      ...dataAttributes,
      ...classes,
    };

    typeCheckConfig(NAME, classes, DefaultClassesType);

    return classes;
  }

  _maybeScheduleHide() {
    if (!this._config.autohide) {
      return;
    }

    if (this._hasMouseInteraction || this._hasKeyboardInteraction) {
      return;
    }

    this._timeout = setTimeout(() => {
      this.hide();
    }, this._config.delay);
  }

  _onInteraction(event, isInteracting) {
    switch (event.type) {
      case "mouseover":
      case "mouseout":
        this._hasMouseInteraction = isInteracting;
        break;
      case "focusin":
      case "focusout":
        this._hasKeyboardInteraction = isInteracting;
        break;
      default:
        break;
    }

    if (isInteracting) {
      this._clearTimeout();
      return;
    }

    const nextElement = event.relatedTarget;
    if (this._element === nextElement || this._element.contains(nextElement)) {
      return;
    }

    this._maybeScheduleHide();
  }

  _setListeners() {
    EventHandler.on(this._element, EVENT_MOUSEOVER, (event) =>
      this._onInteraction(event, true)
    );
    EventHandler.on(this._element, EVENT_MOUSEOUT, (event) =>
      this._onInteraction(event, false)
    );
    EventHandler.on(this._element, EVENT_FOCUSIN, (event) =>
      this._onInteraction(event, true)
    );
    EventHandler.on(this._element, EVENT_FOCUSOUT, (event) =>
      this._onInteraction(event, false)
    );
  }

  _clearTimeout() {
    clearTimeout(this._timeout);
    this._timeout = null;
  }

  // Static

  static jQueryInterface(config) {
    return this.each(function () {
      const data = Toast.getOrCreateInstance(this, config);

      if (typeof config === "string") {
        if (typeof data[config] === "undefined") {
          throw new TypeError(`No method named "${config}"`);
        }

        data[config](this);
      }
    });
  }
}

export default Toast;
