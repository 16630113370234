import ModalFREE from "../../free/components/modal";
import { typeCheckConfig } from "../../util/index";
import Manipulator from "../../dom/manipulator";

/*
------------------------------------------------------------------------
Constants
------------------------------------------------------------------------
*/

const Default = {
  ...ModalFREE.Default,
  modalNonInvasive: false,
};

const DefaultType = {
  ...ModalFREE.DefaultType,
  modalNonInvasive: "boolean",
};

/*
------------------------------------------------------------------------
Class Definition
------------------------------------------------------------------------
*/

class Modal extends ModalFREE {
  constructor(element, config, classes) {
    super(element, config, classes);

    this._config = this._getConfig(config);
    this._isNonInvasive = this._config.modalNonInvasive;

    if (this._isNonInvasive) {
      this._backdrop.update({ isVisible: false });
      this._config.backdrop = false;
      this._config.focus = false;
    }
  }

  // Getters

  static get Default() {
    return Default;
  }

  static get getDefaultType() {
    return DefaultType;
  }

  // Private

  _adjustDialog() {
    super._adjustDialog();

    if (this._isNonInvasive) {
      this._resetAdjustments();
      this._scrollBar.reset();
    }
  }

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...(typeof config === "object" ? config : {}),
    };
    typeCheckConfig(super.NAME, config, DefaultType);
    return config;
  }
}

export default Modal;
