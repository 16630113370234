// TWE FREE COMPONENTS
import Button from "./free/components/button";
import Dropdown from "./free/components/dropdown";
import Collapse from "./free/components/collapse";
import Offcanvas from "./free/components/offcanvas";
import Carousel from "./free/components/carousel";
import Popover from "./free/components/popover";
import ScrollSpy from "./free/navigation/scrollspy";
import Tab from "./free/navigation/tab";
import Tooltip from "./free/components/tooltip";
import Input from "./free/forms/input";
import Ripple from "./free/methods/ripple";

// TWE PRO COMPONENTS
import Alert from "./pro/components/alert";
import Modal from "./pro/components/modal";
import Toast from "./pro/components/toast";
import Animate from "./pro/content-styles/animate";
import Datepicker from "./pro/forms/datepicker";
import Timepicker from "./pro/forms/timepicker";
import Sidenav from "./pro/navigation/sidenav";
import Stepper from "./pro/components/stepper";
import Select from "./pro/forms/select";
import ChipsInput from "./pro/components/chips";
import Chip from "./pro/components/chips/chip";
import Chart from "./pro/data/chart/charts";
import PerfectScrollbar from "./pro/methods/perfect-scrollbar";
import Datatable from "./pro/data/datatables";
import Rating from "./pro/components/rating";
import Popconfirm from "./pro/components/popconfirm";
import Lightbox from "./pro/components/lightbox";
import Validation from "./pro/forms/validation/validation";
import Touch from "./pro/methods/touch";
import SmoothScroll from "./pro/methods/smooth-scroll";
import LazyLoad from "./pro/methods/lazy-load";
import Clipboard from "./pro/methods/clipboard";
import InfiniteScroll from "./pro/methods/infinite-scroll";
import LoadingManagement from "./pro/methods/loading-management";
import Datetimepicker from "./pro/forms/dateTimepicker";
import Sticky from "./pro/methods/sticky";
import Autocomplete from "./pro/forms/autocomplete";
import MultiRangeSlider from "./pro/forms/multi-range";
import initTWE from "./autoinit/index.pro";

const twe = {
  Animate,
  Alert,
  Button,
  ChipsInput,
  Chip,
  Dropdown,
  Carousel,
  Collapse,
  Offcanvas,
  Modal,
  Popover,
  ScrollSpy,
  Select,
  Tab,
  Toast,
  Tooltip,
  Ripple,
  Datepicker,
  Timepicker,
  Sidenav,
  Stepper,
  Input,
  PerfectScrollbar,
  Rating,
  Chart,
  Datatable,
  Popconfirm,
  SmoothScroll,
  Lightbox,
  Validation,
  Touch,
  LazyLoad,
  Datetimepicker,
  Clipboard,
  InfiniteScroll,
  LoadingManagement,
  Autocomplete,
  Sticky,
  MultiRangeSlider,
};

initTWE(twe);

export {
  Animate,
  Alert,
  Button,
  ChipsInput,
  Chip,
  Dropdown,
  Carousel,
  Collapse,
  Offcanvas,
  Modal,
  Popover,
  ScrollSpy,
  Select,
  Tab,
  Toast,
  Tooltip,
  Ripple,
  Datepicker,
  Timepicker,
  Sidenav,
  Stepper,
  Input,
  Chart,
  PerfectScrollbar,
  Datatable,
  Rating,
  Popconfirm,
  Lightbox,
  Validation,
  Touch,
  SmoothScroll,
  LazyLoad,
  Clipboard,
  InfiniteScroll,
  LoadingManagement,
  Datetimepicker,
  Sticky,
  Autocomplete,
  MultiRangeSlider,
  initTWE,
};
